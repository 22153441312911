import * as React from 'react'
import Page from '../Layout/Page'
import * as style from '../css/404.module.scss'

const NotFoundPage = () => {
  return (
    <Page title='Page not found'>
      <div className={style.pageError}>
        <h1>Page not found</h1>
      </div>
    </Page>
  )
}

export default NotFoundPage
